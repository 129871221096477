import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          // d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          // d="M48.655,67.272c0,0.53-0.265,0.848-0.954,0.848l-4.717,0c-1.113,0-1.484-0.424-1.802-1.272l-2.756-7.791l-13.409,0l-2.756,7.95c-0.212,0.689-0.583,1.113-1.431,1.113l-4.717,0c-0.53,0-0.742-0.265-0.742-0.689c0-0.159,0-0.371,0.106-0.583l12.932-34.344c0.265-0.636,0.636-1.06,1.431-1.06l4.24,0c0.795,0,1.272,0.371,1.484,0.954l12.985,34.185c0.106,0.265,0.106,0.477,0.106,0.689z M36.624,53.916l-3.763-11.183c-0.477-1.484-0.795-2.597-1.06-3.445l-0.106,0c-0.265,0.901-0.583,2.014-1.113,3.498l-3.763,11.13l9.805,0z"
          d="M54.925,66.82c0,0.55-0.275,0.88-0.99,0.88l-4.895,0c-1.155,0-1.54-0.44-1.87-1.32l-2.86-8.085l-13.915,0l-2.86,8.25c-0.22,0.715-0.605,1.155-1.485,1.155l-4.895,0c-0.55,0-0.77-0.275-0.77-0.715c0-0.165,0-0.385,0.11-0.605l13.42-35.64c0.275-0.66,0.66-1.1,1.485-1.1l4.4,0c0.825,0,1.32,0.385,1.54,0.99l13.475,35.475c0.11,0.275,0.11,0.495,0.11,0.715z M42.44,51.96l-3.905-11.605c-0.495-1.54-0.825-2.695-1.1-3.575l-0.11,0c-0.275,0.935-0.605,2.09-1.155,3.63l-3.905,11.55l10.175,0z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
